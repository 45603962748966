<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="listAsrama"
            v-model="selAsrama"
            @input="getListKamar()"
            outlined
            label="Asrama"
            style="min-width: 130px;"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="tambah kamar"
            :disable="selAsrama == ''"
            @click="showDialogEdit(-1)"
          ></q-btn>
        </div>

        <q-input outlined label="Cari Kamar" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nama kamar</th>
            <th>asrama</th>
            <th>musyrif/ah</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="selAsrama == ''">
            <td class="text-center" colspan="7">
              Silahkan Pilih Asrama dan Jannah terlebih dahulu
            </td>
          </tr>
          <tr v-else-if="listKamar.length == 0">
            <td class="text-center" colspan="7">Belum ada Asrama dan Jannah</td>
          </tr>
          <tr v-for="(val, i) in filKamar" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.nama_asrama }}</td>
            <td>Ust. {{ showFilter(val.nama_musyrif) }}</td>
            <td>
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                @click="showDialogEdit(val.id)"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-section align="right">
        <q-btn outline color="primary" @click="exportExcel()"
          >export excel</q-btn
        >
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
import DialogPengasuhanEditKamar from "@/components/DialogPengasuhanEditKamar";
export default {
  data() {
    return {
      selJenjang: this.$LIST_JENJANG[0],
      listAsrama: [],
      selAsrama: "",
      listKamar: [],
      searchTerm: "",
    };
  },
  computed: {
    filKamar() {
      if (this.searchTerm == "") return this.listKamar;
      else {
        return this.listKamar.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getListAsrama();
    this.$q.loading.hide();

    // this.getListKamar();
  },
  methods: {
    showFilter(val) {
      if (val) {
        return val;
      } else {
        return "-";
      }
    },
    resetSelection() {
      this.selAsrama = "";
      this.listKamar = [];
      this.listKamarAll = [];
    },
    async getListAsrama() {
      let resp = await this.$http.get("/pengasuhan/asrama/getasrama");
      this.listAsrama = resp.data;
    },
    async exportExcel() {
      let resp = await this.$http.get(
        "/pengasuhan/kamar/listall",
        this.$hlp.getHeader()
      );

      let kamar = resp.data;

      let temp = [];
      this.listAsrama.forEach((element) => {
        var carier = null;
        carier = kamar.filter(function(x) {
          return x.id_asrama == element.value;
        });
        temp.push(carier);
      });
      console.log(temp);

      var wb = this.$xlsx.utils.book_new();

      for (let i in temp) {
        var ws = this.$xlsx.utils.json_to_sheet(temp[i]);
        this.$xlsx.utils.book_append_sheet(
          wb,
          ws,
          `${this.listAsrama[i].label}`
        );
      }

      var now = moment().format("DD MMM YYYY");
      this.$xlsx.writeFile(wb, `Data Musyrif/ah ${now}.xlsx`);
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogPengasuhanEditKamar,
          parent: this,
          idKamar: id,
          asrama: this.selAsrama,
        })
        .onOk((resp) => {
          this.selAsrama = this.listAsrama.find((x) => {
            return x.value == resp.asrama.value;
          });
          this.getListKamar();
        });
    },
    getListKamar() {
      this.$q.loading.show({
        message: "sedang mengambil data",
      });
      this.$http
        .get(
          `/pengasuhan/kamar/list/${this.selAsrama.value}`,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listKamar = resp.data;
          this.$q.loading.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
