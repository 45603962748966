<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-md">
        <q-select readonly v-model="asrama" outlined label="Asrama"></q-select>
        <q-checkbox
          v-model="masal"
          label="Input Kamar Masal"
          v-if="idKamar < 0"
        ></q-checkbox>
        <div v-if="masal" class="q-gutter-sm">
          <a>Nama :</a>
          <div class="row justify-between items-center">
            <q-input
              outlined
              stack-label
              type="number"
              @input="isNameFilled()"
              v-model="bundleKamar.nama_depan"
              label="Awal"
              style="width:45%"
            ></q-input>
            <a class="text-bold">s/d</a>
            <q-input
              outlined
              stack-label
              type="number"
              @input="isNameFilled()"
              v-model="bundleKamar.nama_belakang"
              label="Akhir"
              style="width:45%"
            ></q-input>
          </div>
        </div>
        <div v-else class="column q-gutter-md">
          <q-input
            outlined
            @input="isNameFilled()"
            v-model="bundleKamar.nama"
            label="Nama Kamar"
          ></q-input>
          <q-select
            outlined
            v-model="bundleKamar.id_musyrif"
            use-input
            @filter="filterFn"
            :options="filteredListMusyrif"
            label="Musyrif/ah"
          ></q-select>
        </div>
      </q-card-section>
      <q-card-actions class="row justify-end">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          v-if="idKamar < 0"
          :disable="isNameFilled()"
          color="primary"
          @click="beforeCreate()"
          >Tambah</q-btn
        >
        <q-btn unelevated v-else color="primary" @click="editKamar()"
          >Edit</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idKamar", "asrama"],
  data() {
    return {
      masal: false,

      listAsrama: [],
      listMusyrif: [],
      filteredListMusyrif: [],
      bundleKamar: {
        id_asrama: null,
        id_musyrif: null,
        nama: "",
        nama_depan: "",
        nama_belakang: "",
      },
    };
  },
  async mounted() {
    await this.getListMusyrif();
    if (this.idKamar >= 0) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        "/pengasuhan/kamar/show/" + this.idKamar,
        this.$hlp.getHeader()
      );

      resp.data.id_musyrif = this.listMusyrif.find(
        ({ value }) => value == resp.data.id_musyrif
      );

      this.bundleKamar = resp.data;
      this.$q.loading.hide();
    } else {
      this.bundleKamar.id_asrama = this.asrama;
    }
  },

  methods: {
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredListMusyrif = this.listMusyrif;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredListMusyrif = this.listMusyrif.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getListMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/kamar/getmusyrif/${this.asrama.jenjang}`
      );
      this.listMusyrif = resp.data;
      this.$q.loading.hide();
    },
    async getListAsrama() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get("/pengasuhan/asrama/getlistasrama");
      this.listAsrama = resp.data;
      this.$q.loading.hide();
    },
    isNameFilled() {
      if (this.masal) {
        if (
          this.bundleKamar.nama_depan == "" ||
          this.bundleKamar.nama_belakang == ""
        )
          return true;
        else return false;
      } else {
        if (this.bundleKamar.nama == "" || this.bundleKamar.id_musyrif == null)
          return true;
        else return false;
      }
    },
    async beforeCreate() {
      if (this.masal) {
        this.bulkUpKamar();
      } else {
        this.createKamar();
      }
    },
    async bulkUpKamar() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let data = JSON.parse(JSON.stringify(this.bundleKamar));

      data.id_asrama = data.id_asrama.value;

      await this.$http
        .post("/pengasuhan/kamar/bulk", data, this.$hlp.getHeader())
        .then((resp) => {
          this.onOKClick();
        });
      this.$q.loading.hide();
    },
    async createKamar() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let data = JSON.parse(JSON.stringify(this.bundleKamar));

      data.id_asrama = data.id_asrama.value;
      data.id_musyrif = data.id_musyrif.value;
      console.log(data);

      await this.$http
        .post("/pengasuhan/kamar/tambah", data, this.$hlp.getHeader())
        .then((resp) => {
          this.onOKClick();
        });
      this.$q.loading.hide();
    },
    async editKamar() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let data = JSON.parse(JSON.stringify(this.bundleKamar));
      // console.log(data);
      data.id_musyrif = this.bundleKamar.id_musyrif.value;
      await this.$http
        .put(
          "/pengasuhan/kamar/edit/" + this.idKamar,
          data,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.onOKClick();
        });
      this.$q.loading.hide();
    },
    show() {
      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onDialogHide() {
      this.$emit("hide");
    },
    onOKClick() {
      let data = {
        asrama: this.asrama,
      };
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
